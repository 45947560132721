import React from "react";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';

const ServiceArea = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      {/*=================== service area start ===================*/}
      <div
        className='service-area bg-cover pd-top-120 pd-bottom-90 pd-top-110 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/3.1.png")' }}
      >
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xl-6 col-lg-8'>
              <div className='section-title text-center'>
                <h6 className='sub-title'>{t('services.object')}</h6>
                <h2 className='title'>
                  {t('services.description')}
                </h2>
              </div>
            </div>
          </div>
          <div className='row '>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-4'>
                  <img src='assets/img/icon/18.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>{t('services.service1_title')}</h5>
                  <p className='content mb-3'>
                      {t('services.service1_description')}
                  </p>
                  
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/16.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>{t('services.service2_title')}</h5>
                  <p className='content mb-3'>
                  {t('services.service2_description')}
                  </p>
                 
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/17.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>{t('services.service3_title')}</h5>
                  <p className='content mb-3'>
                  {t('services.service3_description')}
                  </p>
                  
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img src='assets/img/icon/20.svg' alt='img' />
                </div>
                <div className='details'>
                  <h5 className='mb-3'>{t('services.service4_title')}</h5>
                  <p className='content mb-3'>
                  {t('services.service4_description')}
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =================== service area end ===================*/}
    </>
  );
};

export default ServiceArea;
