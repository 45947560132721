import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";
import AboutAreaDataCollection from "../../components/AboutAreaDataCollection";

import ContactAreaAzienda from "../../components/ContactAreaAzienda";

const DataCollection = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"Business Intelligence"} />

      <AboutAreaDataCollection />

      {/* Service Details Area */}


      {/* Footer One */}
      <Footer />
    </>
  );
};

export default DataCollection;
