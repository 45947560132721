import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';


const ServiceDetailsAreaCRM = () => {
    const { t, i18n } = useTranslation();
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  <div style={{ position: 'relative', width: '100%', height: '100%' }}>
                    <img src='assets/img/service/crm_product.png' style={{ display: 'block', margin: 'auto', marginBottom: 40 }} alt='img' />
                  </div>

                  <div className='details'>
                    <h4>{t('CRM.service_title')}</h4>
                    <p>{t('CRM.service_description')}</p>
                    <h5>{t('CRM.what_is_crm')}</h5>
                    <p>{t('CRM.what_is_crm_description')}</p>

                    <div
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            {t('CRM.know_your_customers')}
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            {t('CRM.know_your_customers_description')}
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            {t('CRM.increase_sales')}
                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse '
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            {t('CRM.increase_sales_description')}
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            {t('CRM.improve_customer_service')}
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            <p>{t('CRM.improve_customer_service_description')}</p>
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingFour'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseFour'
                            aria-expanded='false'
                            aria-controls='collapseFour'
                          >
                            {t('CRM.increase_business_efficiency')}
                          </button>
                        </h2>
                        <div
                          id='collapseFour'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingFour'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                            <p>{t('CRM.increase_business_efficiency_description')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Servizi
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/ProtexCRM'> CRM</Link>
                    </li>
                    <li>
                        <Link to='/ProtexSCM'>Protex SCM - Exhibition App</Link>
                    </li>
                    <li>
                      <Link to='/ProtexWMS'> WMS</Link>
                    </li>
                    <li>
                      <Link to='#'> B2B Ecommerce</Link>
                    </li>
                    <li>
                      <Link to='/BI'> Business Intelligence</Link>
                    </li>
                    <li>
                      <Link to='/DataCollection'> MES & Data Collection</Link>
                    </li>
                    <li>
                    <Link to='/service-details'>ORP Planner</Link>
                  </li>
                  <li>
                    <Link to='/service-details'>{t('navbar.QC')}</Link>
                  </li>
                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/service/company410350.jpg' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='#'>
                      Scopri l'azienda
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className='service-area bg-relative pd-top-100'>
              <div className='container'>
                <div className='row'></div>
                <div className='row'>
                  <div className='col-lg-4 col-md-6'>
                    <div className='single-service-inner text-center'>
                      <div className='thumb'>
                        <img src="assets/img/service-icon/security.png" alt='img' style={{ width: '51px', height: '50px' }} />
                      </div>
                      <div className='details'>
                        <h5>
                          <Link to='/service-details'>{t('CRM.security_title')}</Link>
                        </h5>
                        <p>{t('CRM.security_description')}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <div className='single-service-inner text-center'>
                      <div className='thumb'>
                        <img src="assets/img/service-icon/flexibility.png" style={{ width: '51px', height: '50px' }} alt='img' />
                      </div>
                      <div className='details'>
                        <h5>
                          <Link to='/service-details'>{t('CRM.power_and_flexibility_title')}</Link>
                        </h5>
                        <p>{t('CRM.power_and_flexibility_description')}</p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6'>
                    <div className='single-service-inner text-center'>
                      <div className='thumb'>
                        <img src="assets/img/service-icon/eztouse.png" style={{ width: '51px', height: '50px' }} alt='img' />
                      </div>
                      <div className='details'>
                        <h5>
                          <Link to='/service-details'>{t('CRM.ease_of_use_title')}</Link>
                        </h5>
                        <p>{t('CRM.ease_of_use_description')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsAreaCRM;
