import React from "react";

const AboutAreaAzienda = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120 pd-bottom-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about/1.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>LA NOSTRA AZIENDA</h6>
                <h2 className='title'>
                    Leader nell'<span>Innovazione Tessile</span> dal 1979
                </h2>
                <p className='content mb-4 mb-xl-5'>
                Fondata nel maggio del 1979 a Prato, Computer House Srl ha radici 
                profonde nel cuore di un distretto tessile estremamente dinamico, 
                con oltre 1.000 imprese tessili operanti. La nostra storia è una storia 
                di innovazione e dedizione, guidata da un team di management con competenze tecniche nel settore tessile.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>40 anni di esperienza</h5>
                        <p>
                        Con oltre 40 anni di dedizione al settore tessile e dell'abbigliamento, 
                        abbiamo affinato la nostra conoscenza delle sfide uniche affrontate da queste aziende
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Leader nel Settore</h5>
                        <p>
                        Adottato con successo da oltre 300 aziende italiane. 
                        La nostra missione è quella di continuare a guidare l'innovazione nel settore.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaAzienda;
