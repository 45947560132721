import React, { useState } from "react";
import { FaAngleLeft, FaAngleRight, FaStar } from "react-icons/fa";
import Slider from "react-slick";
import ModalVideo from "react-modal-video";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FaPlus } from "react-icons/fa";
import FaqArea from "./FaqArea";

const ServiceDetailsAreaWMS = () => {
  const { t, i18n } = useTranslation();
  const myImageStyle = { width: '50px', height: '50px', margin: 'auto' };
  const [isOpen, setOpen] = useState(false);
  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <FaAngleRight className={className} onClick={onClick} />;
  }
  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <FaAngleLeft className={className} onClick={onClick} />;
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <div className='service-area pd-top-0 pd-bottom-00'>
        <div className='container'>
          <div className='row'>
              <div className='faq-area pd-bottom-120'>
                <div className='container'>
                  <div className='row pd-top-120'>
                    <div className='col-xl-6 col-lg-6 col-md-8 align-self-center'>
                      <div className='about-thumb-inner video-thumb'>
                        <img
                          className='main-img'
                          src='assets/img/service/wms_product.png'
                          alt='img'
                        />
                        
                      </div>
                    </div>
                    <div className='col-xl-6 col-lg-6  align-self-center'>
                      <div className='section-title mb-0 mt-4 mt-lg-0'>
                        <h2 className='title'>
                        💡 Come Funziona il Nostro WMS 💡
                        </h2>
                      </div>
                      <div
                        className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                        id='accordionExample'
                      >
                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingOne'>
                            <button
                              className='accordion-button'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseOne'
                              aria-expanded='true'
                              aria-controls='collapseOne'
                            >
                              🚀 Integrazione in Tempo Reale
                            </button>
                          </h2>
                          <div
                            id='collapseOne'
                            className='accordion-collapse collapse show'
                            aria-labelledby='headingOne'
                            data-bs-parent='#accordionExample'
                          >
                            <div className='accordion-body'>
                            Il nostro WMS è completamente integrato con il tuo sistema ERP PROTEX, il che significa che ogni movimento e operazione nel tuo magazzino è monitorato e aggiornato in tempo reale nel tuo sistema gestionale. Questo livello di connettività assicura che tu abbia sempre una visione chiara e aggiornata delle tue operazioni.
                            </div>
                          </div>
                        </div>
                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingTwo'>
                            <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseTwo'
                              aria-expanded='false'
                              aria-controls='collapseTwo'
                            >
                              🌟 Identificazione e Monitoraggio
                            </button>
                          </h2>
                          <div
                            id='collapseTwo'
                            className='accordion-collapse collapse'
                            aria-labelledby='headingTwo'
                            data-bs-parent='#accordionExample'
                          >
                            <div className='accordion-body'>
                            Ogni articolo che entra nel tuo magazzino viene identificato automaticamente attraverso la lettura del codice a barre. Il nostro WMS riceve e aggiorna immediatamente queste informazioni nel database PROTEX, garantendo una tracciabilità completa e un controllo accurato delle tue scorte.
                            </div>
                          </div>
                        </div>
                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingThree'>
                            <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseThree'
                              aria-expanded='false'
                              aria-controls='collapseThree'
                            >
                              📦 Picking Ottimizzato
                            </button>
                          </h2>
                          <div
                            id='collapseThree'
                            className='accordion-collapse collapse'
                            aria-labelledby='headingThree'
                            data-bs-parent='#accordionExample'
                          >
                            <div className='accordion-body'>
                            Il nostro WMS semplifica il processo di prelievo dagli scaffali del magazzino. Ogni articolo prelevato viene letto tramite codice a barre e l'informazione viene aggiornata in tempo reale. Ciò significa che i tuoi operatori possono effettuare prelievi con maggiore precisione ed efficienza.
                            </div>
                          </div>
                        </div>
                        <div className='accordion-item'>
                          <h2 className='accordion-header' id='headingFour'>
                            <button
                              className='accordion-button collapsed'
                              type='button'
                              data-bs-toggle='collapse'
                              data-bs-target='#collapseFour'
                              aria-expanded='false'
                              aria-controls='collapseFour'
                            >
                              🔄 Rotazione e Movimentazione Efficiente
                            </button>
                          </h2>
                          <div
                            id='collapseFour'
                            className='accordion-collapse collapse'
                            aria-labelledby='headingFour'
                            data-bs-parent='#accordionExample'
                          >
                            <div className='accordion-body'>
                            Utilizzando il nostro software di gestione magazzino, puoi ottimizzare i tempi di movimentazione della merce. Rispondiamo alle tue esigenze specifiche per garantire efficienza, risparmio di tempo e standardizzazione delle movimentazioni.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
          </div>
         
          <div className="row" >

          </div>
        </div>
      </div>
      <div
        className='testimonial-area pd-top-120 pd-bottom-90'
        style={{ backgroundImage: 'url("./assets/img/bg/11.png")' }}
      >
        <div className='container'>
          <div className='section-title'>
            <h6 className='sub-title'>RFID</h6>
            <h2 className='title'>La Tecnologia RFID Integrata nel Nostro WMS</h2>
          </div>
          <div className='testimonial-slider-1  slider-control-round slider-control-dots slider-control-right-top'>
            <Slider {...settings}>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>🌐 Tracciabilità Senza Paragoni</h5>
                  <p>
                  Grazie alla tecnologia RFID, ogni articolo nel tuo 
                  magazzino è dotato di un tag RFID unico. Questi tag 
                  consentono una tracciabilità precisa e in tempo reale di ogni articolo, 
                  da quando entra nel magazzino fino a quando esce per la spedizione. 
                  Non ci sono più congetture o errori umani nella gestione delle tue scorte.
                  </p>

                 
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>🔄 Automazione dei Processi</h5>

                  <p>
                  L'RFID automatizza molti dei processi manuali che altrimenti richiederebbero 
                  tempo e risorse significative. Ad esempio, il conteggio delle scorte diventa 
                  un processo automatico e istantaneo, consentendo di mantenere sempre aggiornate le informazioni sulle giacenze.
                  <br></br><br></br>
                  </p>
                 
                 
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>📊 Analisi Avanzate</h5>
                 
                  <p>
                  La tecnologia RFID fornisce una quantità significativa di dati in tempo reale. 
                  Questi dati possono essere sfruttati per analisi avanzate, consentendoti di identificare tendenze, 
                  modelli e aree di miglioramento nel tuo magazzino.
                  <br></br><br></br><br></br>
                  </p>
                 
                </div>
              </div>
              <div className='item'>
                <div className='single-testimonial-inner style-1 text-center'>
                  <h5>📈 Miglioramento Continuo</h5>
                  
                  <p>
                  Con l'RFID, puoi adottare un approccio basato sui dati per il miglioramento continuo 
                  delle tue operazioni di magazzino. Puoi identificare e affrontare le inefficienze in modo mirato, 
                  ottimizzando ulteriormente il tuo flusso di lavoro.
                  <br></br><br></br><br></br>
                  </p>
                  
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ServiceDetailsAreaWMS;
