import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";
import ServiceDetailsAreaCRM from "../../components/ServiceDetailsAreaCRM";

const ProtexCRM = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"PROTEX CRM"} />

      {/* Service Details Area */}
      <ServiceDetailsAreaCRM />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default ProtexCRM;
