import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import { useTranslation } from 'react-i18next';

const FaqArea = () => {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      {/*==================== Faq area start ====================*/}
      <div className='faq-area pd-bottom-120'>
  <div className='container'>
    <div className='row pd-top-120'>
      <div className='col-xl-6 col-lg-6 col-md-8 align-self-center'>
        <div className='about-thumb-inner video-thumb'>
          <img
            className='main-img'
            src='assets/img/service/scm2ondimage.png'
            alt='img'
          />
        </div>
      </div>
      <div className='col-xl-6 col-lg-6 align-self-center'>
        <div className='section-title mb-0 mt-4 mt-lg-0'>
          <h2 className='title'>{t('SCM.choose_scm_app')}</h2>
        </div>
        <div
          className='accordion accordion-inner style-2 accordion-icon-left mt-3'
          id='accordionExample'
        >
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingOne'>
              <button
                className='accordion-button'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseOne'
                aria-expanded='true'
                aria-controls='collapseOne'
              >
                {t('SCM.real_time_orders')}
              </button>
            </h2>
            <div
              id='collapseOne'
              className='accordion-collapse collapse show'
              aria-labelledby='headingOne'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                {t('SCM.real_time_orders_description')}
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingTwo'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseTwo'
                aria-expanded='false'
                aria-controls='collapseTwo'
              >
                {t('SCM.accuracy_and_error_reduction')}
              </button>
            </h2>
            <div
              id='collapseTwo'
              className='accordion-collapse collapse'
              aria-labelledby='headingTwo'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                {t('SCM.accuracy_and_error_reduction_description')}
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingThree'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseThree'
                aria-expanded='false'
                aria-controls='collapseThree'
              >
                {t('SCM.increase_sales1')}
              </button>
            </h2>
            <div
              id='collapseThree'
              className='accordion-collapse collapse'
              aria-labelledby='headingThree'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                {t('SCM.increase_sales_description1')}
              </div>
            </div>
          </div>
          <div className='accordion-item'>
            <h2 className='accordion-header' id='headingFour'>
              <button
                className='accordion-button collapsed'
                type='button'
                data-bs-toggle='collapse'
                data-bs-target='#collapseFour'
                aria-expanded='false'
                aria-controls='collapseFour'
              >
                {t('SCM.continuous_connectivity')}
              </button>
            </h2>
            <div
              id='collapseFour'
              className='accordion-collapse collapse'
              aria-labelledby='headingFour'
              data-bs-parent='#accordionExample'
            >
              <div className='accordion-body'>
                {t('SCM.continuous_connectivity_description')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      {/* ==================== Faq area end ====================*/}
    </>
  );
};

export default FaqArea;
