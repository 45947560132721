import React from "react";
import AboutAreaAzienda from "../components/AboutAreaAzienda";
import WorkProcessAzienda from "../components/WorkProcessAzienda";
import FaqAreaAzienda from "../components/FaqAreaAzienda";
import CounterAreaAzienda from "../components/CounterAreaAzienda";
import ContactAreaAzienda from "../components/ContactAreaAzienda";

import Footer from "../components/Footer";
import NavBar from "../components/Navbar";


const Azienda = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}

      {/* About Area One */}
      <AboutAreaAzienda />

      {/* FAQ Area One */}
      <FaqAreaAzienda /> 

      {/* Team Area One */}
      {/* <TeamAreaOne /> */}

      {/* Counter Area One */}
      <CounterAreaAzienda /> 

      {/* Contact Area One */}
       <ContactAreaAzienda />

      {/* Work Process One */}
       {/* <WorkProcessAzienda />  */}

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default Azienda;
