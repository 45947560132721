import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const CounterAreaAzienda = () => {
  return (
    <>
      {/* counter area start */}
      <div
        className='counter-area bg-relative bg-cover pd-top-110 pd-bottom-100'
        style={{ backgroundImage: 'url("./assets/img/bg/5.1.png")' }}
      >
        <div className='container pd-bottom-90'>
          
        </div>
      </div>
      {/* counter area end */}
    </>
  );
};

export default CounterAreaAzienda;
