import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";
import ServiceDetailsAreaWMS from "../../components/ServiceDetailsAreaWMS";

const ProtexWMS = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"PROTEX WMS"} />

      {/* Service Details Area */}
      <ServiceDetailsAreaWMS />

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default ProtexWMS;
