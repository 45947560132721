import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';


const ServiceDetailsAreaERP = () => {
    const { t, i18n } = useTranslation();

  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
          <div className='col-lg-8'>
              <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>
                  {/* <div className='thumb'>
                    <img src='assets/img/service/protex850411.png' alt='img' />
                  </div> */}
                  <div className='details'>
                    <h4>
                    {t('PROTEX.title')}
                    </h4>
                    <p>
                    {t('PROTEX.subtitle')}
                    </p>
                    <p>
                    {t('PROTEX.description1')}
                    </p>
                    <h4>{t('PROTEX.db')}</h4>
                    <p>
                    {t('PROTEX.db_desc')}
                    </p>
                    <h4>{t('PROTEX.ma')}</h4>
                    <p>
                    {t('PROTEX.ma_desc')}
                    </p>
                    <p>
                    {t('PROTEX.ma_desc2')}
                    </p>
                    

                    <div
                      className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
                      id='accordionExample'
                    >
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingOne'>
                          <button
                            className='accordion-button'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseOne'
                            aria-expanded='true'
                            aria-controls='collapseOne'
                          >
                            {t('PROTEX.accordion1_header')}
                          </button>
                        </h2>
                        <div
                          id='collapseOne'
                          className='accordion-collapse collapse show'
                          aria-labelledby='headingOne'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                          {t('PROTEX.accordion1_desc')}
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingTwo'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseTwo'
                            aria-expanded='false'
                            aria-controls='collapseTwo'
                          >
                            {t('PROTEX.accordion2_header')}

                          </button>
                        </h2>
                        <div
                          id='collapseTwo'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingTwo'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                          {t('PROTEX.accordion2_desc')}
                          </div>
                        </div>
                      </div>
                      <div className='accordion-item'>
                        <h2 className='accordion-header' id='headingThree'>
                          <button
                            className='accordion-button collapsed'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#collapseThree'
                            aria-expanded='false'
                            aria-controls='collapseThree'
                          >
                            {t('PROTEX.accordion3_header')}
                          </button>
                        </h2>
                        <div
                          id='collapseThree'
                          className='accordion-collapse collapse'
                          aria-labelledby='headingThree'
                          data-bs-parent='#accordionExample'
                        >
                          <div className='accordion-body'>
                          <p>
                          {t('PROTEX.accordion3_desc_p1')}
                          </p>
                          <p>
                          {t('PROTEX.accordion3_desc_p2')}
                          </p>
                          <p>
                          {t('PROTEX.accordion3_desc_p3')}
                          </p>
                          <p>
                          {t('PROTEX.accordion3_desc_p4')}
                          </p>
                          <p>
                          {t('PROTEX.accordion3_desc_p5')}
                          </p>
                          <p>
                          {t('PROTEX.accordion3_desc_p6')}
                          </p>

                          </div>
                        </div>
                      </div>
                    </div>
                    <h4>{t('PROTEX.other_info')}</h4>
                    <p>
                    {t('PROTEX.other_info_desc')}
                    </p>
                    <div className='row'>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info1')}
                          </li>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info2')}
                          </li>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info3')}
                          </li>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info4')}
                          </li>
                        </ul>
                      </div>
                      <div className='col-md-6'>
                        <ul className='single-list-inner style-check style-check mb-3'>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info5')}
                          </li>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info6')}
                          </li>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info7')}
                          </li>
                          <li>
                            <FaCheckCircle /> {t('PROTEX.other_info8')}
                          </li>
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-12'>
              <div className='td-sidebar service-sidebar'>
              {/* <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/service/provaPC.png' alt='img' />
                  </div>                 
                </div> */}
                <div className='widget widget_catagory'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Tutti i servizi
                  </h5>
                  <ul className='catagory-items'>
                    <li>
                      <Link to='/service-details'> CRM</Link>
                    </li>
                    <li>
                        <Link to='/ProtexSCM'>Protex SCM - Exhibition App</Link>
                    </li>
                    <li>
                      <Link to='/service-details'> WMS</Link>
                    </li>
                    <li>
                      <Link to='/service-details'> B2B Ecommerce</Link>
                    </li>
                    <li>
                      <Link to='/service-details'> Business Intelligence</Link>
                    </li>
                    <li>
                      <Link to='/service-details'> MES & Data Collection</Link>
                    </li>
                    <li>
                    <Link to='/service-details'>ORP Planner</Link>
                  </li>
                  <li>
                    <Link to='/service-details'>{t('navbar.QC')}</Link>
                  </li>
                  </ul>
                </div>
                <div className='widget widget_author text-center'>
                  <div className='thumb'>
                    <img src='assets/img/service/company410350.jpg' alt='img' />
                  </div>
                  <div className='details'>
                    <a className='btn btn-base border-radius-5' href='#'>
                      Scopri la nostra
                    </a>
                  </div>
                </div>
                {/* <div className='widget widget_download'>
                  <h5 className='widget-title'>
                    <FaArrowRight /> Download
                  </h5>
                  <ul>
                    <li>
                      <a href='#'>
                        {" "}
                        Profilo azienda <FaAngleDoubleRight />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        {" "}
                        Presentazione prodotto<FaAngleDoubleRight />
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsAreaERP;
