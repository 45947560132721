import React from "react";
import {
  FaAngleDoubleRight,
  FaArrowRight,
  FaCheckCircle,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import { FaPlus } from "react-icons/fa";
import FaqArea from "./FaqArea";


const ServiceDetailsAreaBI = () => {
    const { t, i18n } = useTranslation();
    const myImageStyle = { width: '50px', height: '50px', margin: 'auto' };
  return (
    <>
      {/* ===================== Service Details Area start ===================== */}
      <div className='service-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='row'>
          <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>             
                  <div className='details'>
                        <div className='container'>
                            <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                    <img src='assets/img/service/bi/bi_first.png' style={{display:'block',margin:'auto'}} alt='img' />
                                </div>
                            <div className='col-lg-6 col-md-6'>
                                <div className='section-title mb-0 mt-6 mt-lg-0'>
                                    <h2 className='title'>
                                    Il nostro sistema di <span> Business Intelligence </span>                </h2>
                                </div>
                                    
                                    <p>
                                    BiVision, la nostra soluzione di Business Intelligence, si fonda su un avanzato motore di analisi dati. 
                                    Questo motore estrae dati da una varietà di fonti, tra cui sistemi di gestione, altri software aziendali e risorse esterne, 
                                    li organizza e li prepara per l'elaborazione. BiVision abilita la creazione e la condivisione di report per l'analisi 
                                    dei dati aziendali, la visualizzazione attraverso grafici e indicatori chiave di performance (KPI) e la costruzione di 
                                    categorie di bilancio personalizzate.
                                    </p>
                                </div>
                                
                               
                            </div>
                        </div> 
                  </div>
                </div>
              </div>   
          </div>
        </div>
      </div>
      <div className='service-area pd-top-120 pd-bottom-90' style={{ backgroundImage: 'url("./assets/img/bg/3.1.png")' }}>
        <div className='container'>
          <div className='row'>
          <div className='blog-details-page-content'>
                <div className='single-blog-inner mb-0'>             
                  <div className='details'>
                        <div className='container'>
                            <div className='row'>
                            <div className='col-lg-6 col-md-6'>
                                <div className='section-title mb-2 mt-4 mt-lg-0'>
                                    <h2 className='title'>
                                        Crea e Condividi <span>Report Personalizzati</span> con la Tua Squadra                
                                    </h2>
                                </div>
                                    
                                    <p>
                                    BiVision offre la possibilità di creare report su misura e condividerli in modo efficiente con 
                                    i colleghi, garantendo la costante aggiornamento dei dati e la coerenza delle informazioni condivise. 
                                    Sfrutta appieno le potenzialità degli strumenti di tabella pivot per personalizzare i tuoi report, 
                                    effettuare analisi dettagliate e rappresentare i risultati in forma visuale."
                                    </p>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <img src='assets/img/service/bi/bi_second.png' style={{display:'block',margin:'auto'}} alt='img' />
                                </div>
                            
                                
                               
                            </div>
                        </div> 
                  </div>
                </div>
              </div>
            
            
            
          </div>
        </div>
      </div>
      {/* ===================== Service Details Area End ===================== */}
    </>
  );
};

export default ServiceDetailsAreaBI;
