import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AOS from "aos";
import ScrollToTop from "react-scroll-to-top";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Azienda from "./pages/Azienda";

import ProtexERP from "./pages/Products/ProtexERP";
import ProtexSCM from './pages/Products/ProtexSCM';
import ProtexCRM from './pages/Products/ProtexCRM';
import ProtexWMS from './pages/Products/ProtexWMS';
import BI from './pages/Products/Bi';
import DataCollection from './pages/Products/DataCollection';



import { useEffect } from "react";

import "aos/dist/aos.css";


function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path='/' element={<Home />} />
      <Route exact path='/contact' element={<Contact />} />
      <Route exact path='/ProtexERP' element={<ProtexERP />} />
      <Route exact path='/ProtexCRM' element={<ProtexCRM />} />
      <Route exact path='/ProtexSCM' element={<ProtexSCM />} />
      <Route exact path='/ProtexWMS' element={<ProtexWMS />} />
      <Route exact path='/DataCollection' element={<DataCollection />} />
      <Route exact path='/Bi' element={<BI/>} />

      <Route exact path='/Azienda' element={<Azienda />} />




      </Routes>
      <ScrollToTop smooth color='#246BFD' />
    </BrowserRouter>
  );
}

export default App;
