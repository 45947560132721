import React, { useState } from "react";
import { FaPlus, FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation} from 'react-i18next';
import ReactCountryFlag from "react-country-flag"


const Navbar = () => {

  // const locales = {
  //   en: { title: 'English' },
  //   it: { title: 'Italiano' },
  // };

  const onLanguageChange = (langCode) => {
    i18n.changeLanguage(langCode);
    if(langCode==="IT"){
      setIsIT(true);
    }else{
      setIsIT(false);
    }
}
  const { t, i18n } = useTranslation();
  const [active, setActive] = useState(false);
  const [searchShow, setSearchShow] = useState(false);
  const [isIT, setIsIT] = useState(true);

  const menuActive = () => {
    setActive(!active);
  };
  const searchActive = () => {
    setSearchShow(!searchShow);
    console.log("hell");
  };

  // Control sidebar navigation
  let items = document.querySelectorAll(".menu-item-has-children > a");

  for (let i in items) {
    if (items.hasOwnProperty(i)) {
      items[i].onclick = function () {
        this.parentElement
          .querySelector(".sub-menu")
          .classList.toggle("active");

        this.classList.toggle("open");
      };
    }
  }
  return (
    <>
     
      {/* ==================== Navbar Two Start ====================*/}
      <nav className='navbar navbar-area navbar-area-2 navbar-expand-lg  bg-white '>
        <div className='container nav-container '>
          <div className='responsive-mobile-menu'>
            <button
              onClick={menuActive}
              className={
                active
                  ? "menu toggle-btn d-block d-lg-none open"
                  : "menu toggle-btn d-block d-lg-none"
              }
              data-target='#itech_main_menu'
              aria-expanded='true'
              aria-label='Toggle navigation'
            >
              <span className='icon-left' />
              <span className='icon-right' />
            </button>
          </div>
          <div className='logo'>
            <Link to='/'>
              <img src='assets/img/logo.png' alt='img' />
            </Link>
          </div>         
          <div
            className={
              active
                ? "collapse navbar-collapse sopen"
                : "collapse navbar-collapse"
            }
            id='itech_main_menu'
          >
            <ul className='navbar-nav menu-open text-lg-end'>
              <li className='menu-item'>
                <Link to='/'> {t('navbar.home')}</Link>           
              </li>
              <li className='menu-item-has-children'>
                <a href='#'>{t('navbar.prodotti')}</a>
                {/* style={{ maxHeight: "500px" }} */}
                <ul className='sub-menu' > 
                  <li>
                      <Link to='/ProtexERP'>Protex ERP</Link>
                  </li>
                  <li>
                    <Link to='/ProtexCRM'>Protex CRM</Link>
                  </li>
                  <li>
                    <Link to='/ProtexSCM'>Protex SCM - Exhibition App</Link>
                  </li>
                  <li>
                    <Link to='/ProtexWMS'>Protex WMS</Link>
                  </li>
                  <li>
                    <Link to='/Bi'>Business Intelligence</Link>
                  </li>
                  <li>
                    <Link to='/DataCollection'>MES & Data Collection</Link>
                  </li>
                  <li>
                    <Link to='/ORP'>ORP Planner</Link>
                  </li>
                </ul>
              </li>
              <li className='menu-item'>
              <Link to='/Azienda'>{t('navbar.azienda')}</Link>             
              </li>            
              <li>
                <Link to='/contact'>{t('navbar.contatti')}</Link>
              </li>
            </ul>
          </div>
          <div className='nav-right-part nav-right-part-desktop d-lg-inline-flex align-item-center'>
            <a className='navbar-phone d-xl-inline-block d-none' href='tel:'>
              <span className='icon'>
                <img src='assets/img/icon/1.png' alt='img' />
              </span>
              <span>{t('navbar.help')}</span>
              <h5>(+39) 0574 43851</h5>
            </a>
            {isIT === false  ?   
              <a href="#" onClick={ () => onLanguageChange('IT') } id="flag__it"> 
                  <ReactCountryFlag className="flag__attributes" countryCode="IT" svg />
              </a>           
              :
              <a href="#" onClick={ () => onLanguageChange('en') } id="flag__en">
                <ReactCountryFlag className="flag__attributes" countryCode="GB" svg />
            </a>
            }
            
            
          </div>
        </div>
      </nav>
      {/* ==================== Navbar Two end ====================*/}
    </>
  );
};

export default Navbar;
