import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import Footer from "../../components/Footer";
import NavBar from "../../components/Navbar";
import FaqArea from "../../components/FaqArea";

import ServiceDetailsAreaSCM from "../../components/ServiceDetailsAreaSCM";

const ProtexSCM = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Navigation Bar */}
      <Breadcrumb title={"PROTEX SCM"} />

      <ServiceDetailsAreaSCM />

      {/* Service Details Area */}
      

      {/* Footer One */}
      <Footer />
    </>
  );
};

export default ProtexSCM;
