import React from "react";
import TrackVisibility from "react-on-screen";
import CountUp from "react-countup";
const CounterArea = () => {
  return (
    <>
      {/*================= counter area start {/*=================*/}
      <div className='counter-area mg-top--100'>
        <div className='container pd-bottom-90'>
          <div
            className='bg-base counter-4-area pt-5 border-radius-20'
            data-aos='fade-up'
            data-aos-delay='500'
            data-aos-duration='1500'
          >
            <div className='row'>              
              <div className='col-lg-4 col-md-6'>
                <div className='single-counter-inner media'>
                  <div className='thumb media-left pe-2'>
                    <img src='assets/img/icon/33.svg' alt='img' />
                  </div>
                  <div className='media-body align-self-center'>
                    <h4 className='text-white mb-1'>
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className='counter'>
                              <CountUp delay={0} start={0} end={300} /> +
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h4>
                    <p className='text-white mb-0'>Clienti</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-counter-inner media'>
                  <div className='thumb media-left pe-2'>
                    <img src='assets/img/icon/34.svg' alt='img' />
                  </div>
                  <div className='media-body align-self-center'>
                    <h4 className='text-white mb-1'>
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className='counter'>
                              + <CountUp delay={0} start={0} end={10000} /> 
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h4>
                    <p className='text-white mb-0'>Utenti</p>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6'>
                <div className='single-counter-inner media'>
                  <div className='thumb media-left pe-2'>
                    <img src='assets/img/icon/35.svg' alt='img' />
                  </div>
                  <div className='media-body align-self-center'>
                    <h4 className='text-white mb-1'>
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className='counter'>
                              <CountUp delay={0} start={0} end={60} /> +
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </h4>
                    <p className='text-white mb-0'>Addetti</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*{/*================= counter area end {/*=================*/}
    </>
  );
};

export default CounterArea;
