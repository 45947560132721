import React from "react";
import Marquee from "react-fast-marquee";
import { useTranslation} from 'react-i18next';

const BrandArea = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='about-area bg-gray pt-5 pb-5'>
      <div className='container'>
      <div className='section-title text-center'>
            <div className='row justify-content-center'>
              <div className='col-xl-7 col-lg-10'>
                <h6 className='sub-title'>{t('brandcomponent.title')}</h6>
              </div>
            </div>
          </div>
        <div className='client-slider'>
          <Marquee gradient={false}>
            <div className='thumb'>
              <img src='assets/img/client/1.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/2.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/3.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/4.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/5.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/6.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/7.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/8.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/9.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/10.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/11.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/12.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/13.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/14.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/15.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/16.png' alt='img' />
            </div>
            <div className='thumb'>
              <img src='assets/img/client/17.png' alt='img' />
            </div>           
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BrandArea;
