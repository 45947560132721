import React from "react";
import {
  FaChevronRight,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      {/* ================== Footer Two Start ==================*/}
      <footer className='footer-area mt-0 pd-top-100'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-6'>
              <div className='widget widget_about'>
                
                <div className='details'>
                  <p>
                    Computer House S.R.L.
                  </p>
                  <p>
                    Via Avignone 7, Prato, 59100 Italy
                  </p>
                  <ul className='social-media'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>              
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        
            <div className='col-xl-4 col-lg-4 offset-xl-1 col-md-6'>
              <div className='widget widget_about'>
                <h4 className='widget-title'>Newsletter</h4>
                <div className='details'>
                  <p>
                    Inscriviti alla nostra newsletter per ricevere i nostri aggiornamenti.
                  </p>
                  <div className='subscribe mt-4'>
                    <input type='text' placeholder='E-mail' />
                    <button>
                      <FaChevronRight />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom bg-gray-black'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© Computer House Srl</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='#'>Privacy Policy</a>
                <a href='#'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* ================== Footer Two  end ==================*/}
    </>
  );
};

export default Footer;
