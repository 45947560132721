import React from "react";
import Marquee from "react-fast-marquee";
import { FaCheckCircle, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
const AboutAreaDataCollection = () => {
  return (
    <>
      {/* =============== About Area Three End ===============*/}
      <div className='about-area bg-gradient-gray pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div
              className='col-lg-6 '
              data-aos='fade-right'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='about-thumb-inner mb-4 mb-lg-0'>
                <img
                  className='main-img'
                  src='assets/img/service/datacollection/dc1.png'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 wow animated fadeInRight'
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mb-0'>
                <h6 className='sub-title'>TEXMON</h6>
                <h2 className='title'>
                Raccolta Dati in Tempo Reale per il Tuo Successo Aziendale
                </h2>
                <p className='content mb-4'>
                  TexMon è la soluzione all'avanguardia per la raccolta dati e la gestione della produzione nei 
                  settori tessili. Con un'attenzione minuziosa ai dettagli, forniamo una suite completa di 
                  strumenti progettati per migliorare l'efficienza e l'efficacia delle tue operazioni di produzione.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Rilevamento della Produzione
                      </li>
                      <li>
                        <FaCheckCircle /> Statistiche e Grafici delle Fermate
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Statistiche e Immagini dei Difetti
                      </li>
                      <li>
                        <FaCheckCircle /> Gantt della Sala
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Statistica sui Turni e Addetti
                      </li>
                      <li>
                        <FaCheckCircle /> Ricezione delle Commesse di Lavoro
                      </li>
                    </ul>
                  </div>
                  <div className='col-md-6'>
                    <ul className='single-list-inner style-check style-heading style-check mb-3'>
                      <li>
                        <FaCheckCircle /> Tablet per l'Unità Produttiva
                      </li>
                      <li>
                        <FaCheckCircle /> Resa Commerciale e Produttiva
                      </li>
                    </ul>
                  </div>
                </div>
                <p className='content'>
                Nella nostra continua missione di innovazione, lavoriamo instancabilmente per 
                migliorare il nostro prodotto e offrire sempre nuove funzionalità e capacità. 
                Siamo entusiasti di essere il vostro partner di fiducia mentre affrontate le sfide in continua evoluzione dell'industria tessile.
                </p>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        
        </div>
      </div>
      <div className='faq-area pd-bottom-120'>
        <div className='container'>
          <div className='row pd-top-120'>
          
            <div className='col-xl-8 col-lg-6  align-self-center'>
              <div className='section-title mb-0 mt-4 mt-lg-0'>
                <h2 className='title'>
                Perché scegliere <span>TEXMON</span>
                </h2>
              </div>
              <div
                className='accordion accordion-inner style-2 accordion-icon-left mt-3'
                id='accordionExample'
              >
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingOne'>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseOne'
                      aria-expanded='true'
                      aria-controls='collapseOne'
                    >
                      Tempo Reale
                    </button>
                  </h2>
                  <div
                    id='collapseOne'
                    className='accordion-collapse collapse show'
                    aria-labelledby='headingOne'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    I dati vengono raccolti e trasmessi in tempo reale. 
                    Questo significa che avete accesso a informazioni aggiornate costantemente, 
                    consentendo una reattività immediata alle sfide di produzione.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingTwo'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseTwo'
                      aria-expanded='false'
                      aria-controls='collapseTwo'
                    >
                      Rapporti Dettagliati
                    </button>
                  </h2>
                  <div
                    id='collapseTwo'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingTwo'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Forniamo rapporti dettagliati basati sui dati raccolti. Questi rapporti includono statistiche, 
                    grafici e analisi che possono essere utilizzati per valutare le prestazioni, identificare trend e prendere decisioni informate.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingThree'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseThree'
                      aria-expanded='false'
                      aria-controls='collapseThree'
                    >
                      Integrazione Completa
                    </button>
                  </h2>
                  <div
                    id='collapseThree'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingThree'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    La nostra soluzione di raccolta dati è completamente integrata con il nostro sistema di gestione.
                     Questo significa che i dati raccolti sono immediatamente accessibili da qualsiasi parte del sistema, 
                     consentendo una gestione coordinata e senza soluzione di continuità.
                    </div>
                  </div>
                </div>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id='headingFour'>
                    <button
                      className='accordion-button collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target='#collapseFour'
                      aria-expanded='false'
                      aria-controls='collapseFour'
                    >
                      Assistenza e Supporto
                    </button>
                  </h2>
                  <div
                    id='collapseFour'
                    className='accordion-collapse collapse'
                    aria-labelledby='headingFour'
                    data-bs-parent='#accordionExample'
                  >
                    <div className='accordion-body'>
                    Offriamo assistenza e supporto completo per garantire che la raccolta dei dati si svolga senza intoppi. 
                    Il nostro team è a vostra disposizione per risolvere problemi e garantire un'esperienza senza problemi.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6 col-md-8 align-self-center'>
              <div className='about-thumb-inner video-thumb'>
                <img
                  className='main-img'
                  src='assets/img/service/datacollection/dc2.png'
                  alt='img'
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== About Area Three End ===============*/}
    </>
  );
};

export default AboutAreaDataCollection;
