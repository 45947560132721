import React from "react";
import AboutArea from "../components/AboutArea";
import Banner from "../components/Banner";
import BlogArea from "../components/BlogArea";
import BrandArea from "../components/BrandArea";
import CareerArea from "../components/CareerArea";
import ContactArea from "../components/ContactArea";
import CounterArea from "../components/CounterArea";
import FaqArea from "../components/FaqArea";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ProjectArea from "../components/ProjectArea";
import ServiceArea from "../components/ServiceArea";
import TeamArea from "../components/TeamArea";
import Testimonial from "../components/Testimonial";
import ContactAreaAzienda from "../components/ContactAreaAzienda";

const Home = () => {
  return (
    <>
      {/* Navigation Bar */}
      <Navbar />

      {/* Banner Four */}
      <Banner />
      


      {/* About Area Four */}
      <AboutArea />
      <CounterArea />
      {/* Service Area four */}
      <ServiceArea />

      {/* FAQ Area Two 
       <FaqArea />
      */}
     

      {/* Team Area Two 
      <TeamArea />
      */}
      

      {/* Career Area One 
      <CareerArea />*/}
      

      {/* Project Area One 
      <ProjectArea />*/}
      

      {/* Contact Area Four 
      <ContactArea />*/}
      
      {/* Counter Area Four */}

      {/* Testimonial Three */}
     

      

      {/* Blog Area Four 
      <BlogArea />
      */}
      

      {/* Brand Area One */}
      <ContactAreaAzienda />

      <BrandArea />
      <Testimonial />


      {/* Footer Three */}
      <Footer />
    </>
  );
};

export default Home;
