import React from "react";
import { useTranslation } from 'react-i18next';
import FaqArea from './FaqArea';  // Assicurati di importare FaqArea dal percorso corretto

const ServiceDetailsAreaSCM = () => {
  const { t } = useTranslation();

  return (
    <div className='service-area pd-top-120 pd-bottom-90'>
  <div className='container'>
    <div className='row'>
      <div className='blog-details-page-content'>
        <div className='single-blog-inner mb-0'>
          <div className='details'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-6 col-md-6'>
                  <h2 className='title'>{t('SCM.product_title')}</h2>
                  <p>{t('SCM.product_description')}</p>
                </div>
                <div className='col-lg-6 col-md-6'>
                  <img src='assets/img/service/SCM_product.png' style={{ display: 'block', margin: 'auto' }} alt='img' />
                </div>
              </div>
            </div>
          </div>
          <FaqArea />

          <h5>{t('SCM.why_choose_scm')}</h5>

          <div
            className='accordion accordion-inner accordion-icon-left mt-3 mb-4'
            id='accordionExample'
          >
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingOne'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseOne'
                  aria-expanded='true'
                  aria-controls='collapseOne'
                >
                  {t('SCM.know_your_customers')}
                </button>
              </h2>
              <div
                id='collapseOne'
                className='accordion-collapse collapse show'
                aria-labelledby='headingOne'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  {t('SCM.know_your_customers_description')}
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingTwo'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseTwo'
                  aria-expanded='false'
                  aria-controls='collapseTwo'
                >
                  {t('SCM.increase_sales')}
                </button>
              </h2>
              <div
                id='collapseTwo'
                className='accordion-collapse collapse '
                aria-labelledby='headingTwo'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  {t('SCM.increase_sales_description')}
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingThree'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseThree'
                  aria-expanded='false'
                  aria-controls='collapseThree'
                >
                  {t('SCM.improve_customer_service')}
                </button>
              </h2>
              <div
                id='collapseThree'
                className='accordion-collapse collapse'
                aria-labelledby='headingThree'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  {t('SCM.improve_customer_service_description')}
                </div>
              </div>
            </div>
            <div className='accordion-item'>
              <h2 className='accordion-header' id='headingFour'>
                <button
                  className='accordion-button collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#collapseFour'
                  aria-expanded='false'
                  aria-controls='collapseFour'
                >
                  {t('SCM.increase_business_efficiency')}
                </button>
              </h2>
              <div
                id='collapseFour'
                className='accordion-collapse collapse'
                aria-labelledby='headingFour'
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body'>
                  {t('SCM.increase_business_efficiency_description')}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default ServiceDetailsAreaSCM;
